import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './components/header/Header';
import SideMenu from './components/SideMenu/SideMenu';
import AllMentor from './Pages/allMentor/AllMentor';
import ViewProfile from './Pages/Profile/ViewProfile';
import ViewMentorProfile from './Pages/Profile/ViewMentorProfile';
import EditProfile from './Pages/Profile/EditProfile';
import CallConfirm from './Pages/CallConfirm/CallConfirm';
import CallHistory from './Pages/CallHistory/CallHistory';
import Feedback from './Pages/Feedback/Feedback';
import FeedbackPreview from './Pages/Feedback/FeedbackPreview';
import { PrivateRoute } from './helpers/PrivateRoute';
import LoginPage from './Pages/authentication/LoginPage';
import {menteeSliceData} from "./reducer/menteeSlice"
import { useSelector } from 'react-redux';

function App() {

  const pathname = window.location.pathname;
  const { token } = useSelector(menteeSliceData); 

  return (
    <>
      {/* {(pathname !== "/login" && token !== null) && <Header />} */}
      <Header />
      <div className='appContainer'>
        <BrowserRouter>
          {(pathname !== "/login" && token !== null) && <SideMenu />}
          <div className="sectionAdjust">
            <Routes>
            <Route path='/login' exact element={<LoginPage />} />
              <Route path='/' exact element={<PrivateRoute />}>
              <Route path="/" exact element={<AllMentor />} />
              <Route path="/profile" exact element={<ViewProfile />} />
              <Route path="/viewMentorProfile/:id" exact element={<ViewMentorProfile />} />
              <Route path="/editProfile" exact element={<EditProfile />} />
              <Route path="/callConfirm/:id" exact element={<CallConfirm />} />
              <Route path="/callHistory" exact element={<CallHistory />} />
              <Route path="/feedback" exact element={<Feedback />} />
              <Route path="/feedbackPreview" exact element={<FeedbackPreview />} />
              </Route>
            </Routes>
          </div>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
