import moment from 'moment';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PageLoader from '../../components/PageLoader/PageLoader';
import { getInteviewDetails, menteeSliceData } from '../../reducer/menteeSlice';
import './CallConfirmStyle.css';
import { useNavigate } from 'react-router-dom';


const CallConfirm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();

    const {
        isFetching,
        isSuccess,
        isError,
        errorMessage,
        interviewConfirmed
      } = useSelector(menteeSliceData);

    useEffect(() => {
        dispatch(getInteviewDetails(id));
    },[])

  return (
    <>
    {isFetching && <PageLoader />}
        <div className="headerSmall">
            <p className="HighlightHeading"><span className="parent"  onClick={() => navigate("/")}>All Mentors </span>/ Call Scheduled</p>
        </div>

        <div className="callWrapper mt-10">

            <div className="callMidBox">
                <h1 className="bigHeading">Your call is confirmed!</h1>
                <p className="text-2 mt-20">It is scheduled for</p>
                <div className="scheduleWrap mt-20">
                    <span className='pillBox'>{interviewConfirmed?.time ? interviewConfirmed.time : "---"}</span>
                    <span className='pillBox'>{interviewConfirmed?.startTime ? moment(interviewConfirmed.startTime).format("dddd, MMMM DD, YYYY") : "---"}</span>
                </div>
                <div className="mentorBoxCall">
                    <div className="mentorBox">
                        {interviewConfirmed?.Mentor?.profilePicUrl ? <img className='rounderImg' src={interviewConfirmed?.Mentor?.profilePicUrl} alt="" />:<img className='rounderImg' src="/assets/img/demoUser.png" alt="" />}
                        <div className="mentorDetail">
                            <div className="masterName">{interviewConfirmed?.Mentor?.firstName ? `${interviewConfirmed?.Mentor?.firstName} ${interviewConfirmed?.Mentor?.lastName}` : "---"}</div>
                            <div className="masterDetail mt-5">{interviewConfirmed?.Mentor?.designation ? `${interviewConfirmed?.Mentor?.designation}, ${interviewConfirmed?.Mentor?.mentorshipProfession?.company}` : "---"}</div>
                        </div>
                    </div>
                        <div className="masterOtherDetails mt-15">
                            <p>Areas of expertise: {interviewConfirmed?.Mentor?.mentorshipTopics?.length > 0 ? interviewConfirmed?.Mentor?.mentorshipTopics?.map((topic,i) => (
                                <>
                                {i ? "," : ""}
                                <span>{topic.Topic}</span>
                                </>
                            )): "---"}</p>
                            <p className='mt-15'>Years of Experience: {interviewConfirmed?.Mentor?.mentorshipProfession?.yrsOfExp ? interviewConfirmed?.Mentor?.mentorshipProfession?.yrsOfExp : "---"}</p>
                            {/* <p className='mt-15'>Ex - KPMG Australia, DXC Technology, PwC</p> */}
                        </div>
                </div>
                <p className="calllink mt-20">Meeting Link: <a onClick={() => window.open(interviewConfirmed?.meetingLink)}>{interviewConfirmed?.meetingLink ? interviewConfirmed?.meetingLink : "---"}</a></p>
                <p className="text-2 mt-50">Meeting link & other details will also be shared on your registered email address</p>
            </div>

        </div>
    </>
  )
}

export default CallConfirm