import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Button from "../../components/Button/Button";
// import { getMentorDetails } from "../../reducer/menteeSlice";
import { useNavigate } from "react-router-dom";
import "./profileStyle.css";
import { getMenteeDetails, menteeSliceData , changePassword, clearState } from "../../reducer/menteeSlice";
import PageLoader from "../../components/PageLoader/PageLoader";
import ChangePassPopup from "../../components/changePassPopup/ChangePassPopup";
import ErrorToast from "../../components/Toast/ErrorToast";
import SuccessToast from '../../components/Toast/SuccessToast';

const ViewProfile = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const type = searchParams.get("type");
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getMenteeDetails());
  }, []);

  const { isFetching, isSuccess, isError, errorMessage, menteeDetails } =
    useSelector(menteeSliceData);
  console.log(menteeDetails, "menteeDetails");

  // change Password
  const [isActive, setisActive] = useState(false);
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [isShowSuccess, setIsShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const passwordTemplate = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const validationTemplate = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    error: "",
  };
  const [password, setPassword] = useState(passwordTemplate);
  const [passwordValidation, setPasswordValidation] =
    useState(validationTemplate);

  const handlePopupClose = () => {
    setisActive(false);
    setPassword(passwordTemplate);
  };

  const handlePassChange = (e) => {
    setPasswordValidation(validationTemplate);
    const { name, value } = e.target;
    setPassword((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleClickChangePass = () => {
    if (!validation()) {
      const obj = {
        oldPassword: password.oldPassword,
        newPassword: password.newPassword,
        menteeId :   menteeDetails.id
      };
      dispatch(changePassword(obj));
    }
  };

  const validation = () => {
    for (const key in password) {
      if (!password[key]) {
        setPasswordValidation((prev) => ({
          ...prev,
          [key]: "Please fill this field",
        }));
        return true;
      }
    }
    if (password.newPassword !== password.confirmPassword) {
      setPasswordValidation((prev) => ({
        ...prev,
        error: "Password & Confirm Password do not match",
      }));
      return true;
    }
    if (password.confirmPassword.length < 6) {
      setPasswordValidation((prev) => ({
        ...prev,
        error: "Password should be atleast 6 digit",
      }));
      return true;
    }
    return false;
  };

  const handleKeyPressSubmit = (e) => {
    if (e.key === "Enter") {
      if (!validation()) {
        const obj = {
          oldPassword: password.oldPassword,
          newPassword: password.newPassword,
          menteeId :   menteeDetails.id
        };
        dispatch(changePassword(obj));
      }
    }
  };

  useEffect(() => {
    if (isError) {
      setIsShowError(true);
      setErrMessage(errorMessage);
      dispatch(clearState());
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      setPassword(passwordTemplate);
      setIsShowSuccess(true);
      setSuccessMessage("Password successfully changed!");
      setisActive(false);
      dispatch(clearState());
    }
  }, [isSuccess]);

  return (
    <>
      {isFetching && <PageLoader />}
      <ChangePassPopup
        isActive={isActive}
        handleClose={handlePopupClose}
        value={password}
        onChange={handlePassChange}
        onClick={handleClickChangePass}
        validation={passwordValidation}
        onKeyPress={handleKeyPressSubmit}
      />
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <SuccessToast
        show={isShowSuccess}
        setShow={() => setIsShowSuccess(false)}
        message={successMessage}
      />
      <div className="headerSmall withButton">
        <p className="HighlightHeading">My Profile</p>
        <Button
          className={"imgButton GreenSlotBorder"}
          src={"/assets/icons/user-edit.svg"}
          name={"Edit Profile"}
          onClick={() => navigate("/editProfile")}
        />
      </div>

      <div className="mentorProfileContainer mt-10">
        <div className="mentorProfile">
          <img
            src={
              menteeDetails?.profileImg !== null
                ? menteeDetails?.profileImg
                : "/assets/img/demoUser.png"
            }
            alt=""
            className="mentorImg"
          />
          <div className="mentorDetailsProfile">
            <p className="mentorNameHeading">
              {(menteeDetails?.firstName ? menteeDetails?.firstName : "---") +
                " " +
                (menteeDetails?.lastName ? menteeDetails?.lastName : "---")}
              {menteeDetails?.linkedin === !null && (
                <div
                  className="linkdinBtn"
                  onClick={() =>
                    window.open("https://" + menteeDetails?.linkedin)
                  }
                >
                  Linkedin
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.8125 13.75H2.1875"
                      stroke="#3397EF"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.875 2.1875L3.125 10.9375"
                      stroke="#3397EF"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.875 8.60625V2.1875H5.45625"
                      stroke="#3397EF"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              )}
            </p>
            <p className="designation">
              {menteeDetails?.mentorshipProfession?.designation ? menteeDetails?.mentorshipProfession?.designation + " at " : "" }
               { menteeDetails?.mentorshipProfession?.company? menteeDetails?.mentorshipProfession?.company :""}
            </p>
          </div>
        </div>

        <div className="tabContentOuter mentorRequest mt-30">
          <div className="tabContent active">
            <div className="personalDetailOuter">
              <p className="heading">Personal Details</p>
              <div className="personalDetailContainer">
                <div className="cardsOuter">
                  <div className="card">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.6253 11.5833H14.1374C13.9394 11.5833 13.7458 11.6421 13.5812 11.7523L11.742 12.9833C11.442 13.1833 11.092 13.275 10.7503 13.275C10.4587 13.275 10.167 13.2083 9.90032 13.0583C9.53496 12.8652 9.25786 12.525 9.10179 12.1359C8.93704 11.7252 8.66065 11.3339 8.26685 11.1321C7.92851 10.9586 7.62203 10.7384 7.35866 10.475C6.64199 9.75831 6.25033 8.74998 6.25033 7.62498V4.37498V4.16665C6.25033 3.70641 5.87723 3.33331 5.41699 3.33331C3.16699 3.33331 1.66699 4.45831 1.66699 7.08331V11.5833C1.66699 14.2083 3.16699 15.3333 5.41699 15.3333H8.54199V17.7083H6.16699C5.82533 17.7083 5.54199 17.9916 5.54199 18.3333C5.54199 18.675 5.82533 18.9583 6.16699 18.9583H12.167C12.5087 18.9583 12.792 18.675 12.792 18.3333C12.792 17.9916 12.5087 17.7083 12.167 17.7083H9.79199V15.3333H12.917C14.8585 15.3333 16.2415 14.4957 16.5843 12.5795C16.6816 12.0358 16.2193 11.5833 15.667 11.5833H15.6253Z"
                        fill="#21222D"
                      />
                      <path
                        d="M15.625 1.66669H10.2083C8.96667 1.66669 8.03333 2.30002 7.66667 3.33335C7.55833 3.65002 7.5 4.00002 7.5 4.37502V7.62502C7.5 8.43335 7.76667 9.11669 8.24167 9.59169C8.71667 10.0667 9.4 10.3334 10.2083 10.3334V11.4917C10.2083 11.9167 10.6917 12.175 11.05 11.9417L13.4583 10.3334H15.625C16 10.3334 16.35 10.275 16.6667 10.1667C17.7 9.80002 18.3333 8.86669 18.3333 7.62502V4.37502C18.3333 2.75002 17.25 1.66669 15.625 1.66669ZM10.5167 6.66669C10.1667 6.66669 9.89167 6.38335 9.89167 6.04169C9.89167 5.70002 10.1667 5.41669 10.5167 5.41669C10.8667 5.41669 11.1417 5.70002 11.1417 6.04169C11.1417 6.38335 10.8667 6.66669 10.5167 6.66669ZM12.8667 6.66669C12.5167 6.66669 12.2417 6.38335 12.2417 6.04169C12.2417 5.70002 12.525 5.41669 12.8667 5.41669C13.2083 5.41669 13.4917 5.70002 13.4917 6.04169C13.4917 6.38335 13.2083 6.66669 12.8667 6.66669ZM15.2083 6.66669C14.8583 6.66669 14.5833 6.38335 14.5833 6.04169C14.5833 5.70002 14.8667 5.41669 15.2083 5.41669C15.55 5.41669 15.8333 5.70002 15.8333 6.04169C15.8333 6.38335 15.55 6.66669 15.2083 6.66669Z"
                        fill="#21222D"
                      />
                      <path
                        d="M18.3333 4.37502V7.62502C18.3333 8.86669 17.7 9.80002 16.6667 10.1667C16.35 10.275 16 10.3334 15.625 10.3334H13.4583L11.05 11.9417C10.6917 12.175 10.2083 11.9167 10.2083 11.4917V10.3334C9.4 10.3334 8.71667 10.0667 8.24167 9.59169C7.76667 9.11669 7.5 8.43335 7.5 7.62502V4.37502C7.5 4.00002 7.55833 3.65002 7.66667 3.33335C8.03333 2.30002 8.96667 1.66669 10.2083 1.66669H15.625C17.25 1.66669 18.3333 2.75002 18.3333 4.37502Z"
                        fill="#21222D"
                      />
                    </svg>
                    <div className="cardDetail">
                      <p className="heading">Email</p>
                      <p className="content">{menteeDetails?.officialEmail}</p>
                    </div>
                  </div>
                  <div className="card">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.20866 12.4584L7.66699 14C7.34199 14.325 6.82533 14.325 6.49199 14.0084C6.40033 13.9167 6.30866 13.8334 6.21699 13.7417C5.35866 12.875 4.58366 11.9667 3.89199 11.0167C3.20866 10.0667 2.65866 9.11669 2.25866 8.17502C1.86699 7.22502 1.66699 6.31669 1.66699 5.45002C1.66699 4.88335 1.76699 4.34169 1.96699 3.84169C2.16699 3.33335 2.48366 2.86669 2.92533 2.45002C3.45866 1.92502 4.04199 1.66669 4.65866 1.66669C4.89199 1.66669 5.12533 1.71669 5.33366 1.81669C5.55033 1.91669 5.74199 2.06669 5.89199 2.28335L7.82533 5.00835C7.97533 5.21669 8.08366 5.40835 8.15866 5.59169C8.23366 5.76669 8.27533 5.94169 8.27533 6.10002C8.27533 6.30002 8.21699 6.50002 8.10033 6.69169C7.99199 6.88335 7.83366 7.08335 7.63366 7.28335L7.00033 7.94169C6.90866 8.03335 6.86699 8.14169 6.86699 8.27502C6.86699 8.34169 6.87533 8.40002 6.89199 8.46669C6.91699 8.53335 6.94199 8.58335 6.95866 8.63335C7.10866 8.90835 7.36699 9.26669 7.73366 9.70002C8.10866 10.1334 8.50866 10.575 8.94199 11.0167C9.02532 11.1 9.11699 11.1834 9.20033 11.2667C9.53366 11.5917 9.54199 12.125 9.20866 12.4584Z"
                        fill="#21222D"
                      />
                      <path
                        d="M18.3083 15.275C18.3083 15.5083 18.2667 15.75 18.1833 15.9833C18.1583 16.05 18.1333 16.1167 18.1 16.1833C17.9583 16.4833 17.775 16.7667 17.5333 17.0333C17.125 17.4833 16.675 17.8083 16.1667 18.0167C16.1583 18.0167 16.15 18.025 16.1417 18.025C15.65 18.225 15.1167 18.3333 14.5417 18.3333C13.6917 18.3333 12.7833 18.1333 11.825 17.725C10.8667 17.3167 9.90833 16.7667 8.95833 16.075C8.63333 15.8333 8.30833 15.5917 8 15.3333L10.725 12.6083C10.9583 12.7833 11.1667 12.9167 11.3417 13.0083C11.3833 13.025 11.4333 13.05 11.4917 13.075C11.5583 13.1 11.625 13.1083 11.7 13.1083C11.8417 13.1083 11.95 13.0583 12.0417 12.9667L12.675 12.3417C12.8833 12.1333 13.0833 11.975 13.275 11.875C13.4667 11.7583 13.6583 11.7 13.8667 11.7C14.025 11.7 14.1917 11.7333 14.375 11.8083C14.5583 11.8833 14.75 11.9917 14.9583 12.1333L17.7167 14.0917C17.9333 14.2417 18.0833 14.4167 18.175 14.625C18.2583 14.8333 18.3083 15.0417 18.3083 15.275Z"
                        fill="#21222D"
                      />
                    </svg>

                    <div className="cardDetail">
                      <p className="heading">Phone Number</p>
                      <p className="content">{menteeDetails?.mobileNumber}</p>
                    </div>
                  </div>
                  <div className="card">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.8417 15.4917C12.5084 15.4917 12.2334 15.2167 12.2334 14.8833C12.2334 14.55 12.5084 14.275 12.8417 14.275C15.1917 14.275 17.1084 12.3583 17.1084 10.0083C17.1084 7.65833 15.1917 5.74166 12.8417 5.74166C10.4917 5.74166 8.57507 7.65833 8.57507 10.0083C8.57507 10.3417 8.30007 10.6167 7.96673 10.6167C7.6334 10.6167 7.3584 10.3417 7.3584 10.0083C7.3584 6.98333 9.81673 4.51666 12.8501 4.51666C15.8834 4.51666 18.3334 6.975 18.3334 10C18.3334 13.025 15.8751 15.4917 12.8417 15.4917Z"
                        fill="#21222D"
                      />
                      <path
                        d="M7.15841 4.50836C7.49175 4.50836 7.76675 4.78336 7.76675 5.1167C7.76675 5.45003 7.49175 5.72503 7.15841 5.72503C4.80841 5.72503 2.89175 7.6417 2.89175 9.9917C2.89175 12.3417 4.80841 14.2584 7.15841 14.2584C9.50841 14.2584 11.4251 12.3417 11.4251 9.9917C11.4251 9.65836 11.7001 9.38336 12.0334 9.38336C12.3667 9.38336 12.6417 9.65836 12.6417 9.9917C12.6417 13.0167 10.1834 15.4834 7.15008 15.4834C4.11675 15.4834 1.66675 13.025 1.66675 10C1.66675 6.97503 4.12508 4.50836 7.15841 4.50836Z"
                        fill="#21222D"
                      />
                    </svg>
                    <div className="cardDetail">
                      <p className="heading">Linkedin ID</p>
                      <p className="content">
                        {menteeDetails?.linkedin
                          ? menteeDetails?.linkedin
                          : "---"}
                      </p>
                    </div>
                  </div>
                  <div className="card">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.8417 15.4917C12.5084 15.4917 12.2334 15.2167 12.2334 14.8833C12.2334 14.55 12.5084 14.275 12.8417 14.275C15.1917 14.275 17.1084 12.3583 17.1084 10.0083C17.1084 7.65833 15.1917 5.74166 12.8417 5.74166C10.4917 5.74166 8.57507 7.65833 8.57507 10.0083C8.57507 10.3417 8.30007 10.6167 7.96673 10.6167C7.6334 10.6167 7.3584 10.3417 7.3584 10.0083C7.3584 6.98333 9.81673 4.51666 12.8501 4.51666C15.8834 4.51666 18.3334 6.975 18.3334 10C18.3334 13.025 15.8751 15.4917 12.8417 15.4917Z"
                        fill="#21222D"
                      />
                      <path
                        d="M7.15841 4.50836C7.49175 4.50836 7.76675 4.78336 7.76675 5.1167C7.76675 5.45003 7.49175 5.72503 7.15841 5.72503C4.80841 5.72503 2.89175 7.6417 2.89175 9.9917C2.89175 12.3417 4.80841 14.2584 7.15841 14.2584C9.50841 14.2584 11.4251 12.3417 11.4251 9.9917C11.4251 9.65836 11.7001 9.38336 12.0334 9.38336C12.3667 9.38336 12.6417 9.65836 12.6417 9.9917C12.6417 13.0167 10.1834 15.4834 7.15008 15.4834C4.11675 15.4834 1.66675 13.025 1.66675 10C1.66675 6.97503 4.12508 4.50836 7.15841 4.50836Z"
                        fill="#21222D"
                      />
                    </svg>
                    <div className="cardDetail">
                      <div className="changePass">
                        <p class="heading">Password</p>
                        <span
                          onClick={() => {
                            setisActive(true);
                          }}
                        >
                          Change Password
                        </span>
                      </div>
                      <p class="content">{"******"}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="personalDetailOuter mt-40">
              <p className="heading">Professional Details</p>
              <div className="personalDetailContainer">
                <div className="cardsOuter">
                  <div className="card">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.5749 5.81664C16.8665 5.03331 15.6832 4.64164 13.9665 4.64164H13.7665V4.60831C13.7665 3.20831 13.7665 1.47498 10.6332 1.47498H9.36653C6.2332 1.47498 6.2332 3.21664 6.2332 4.60831V4.64998H6.0332C4.3082 4.64998 3.1332 5.04164 2.42487 5.82498C1.59987 6.74164 1.62487 7.97498 1.7082 8.81664L1.71654 8.87497L1.77732 9.51326C1.79159 9.66303 1.87234 9.79841 1.99834 9.88063C2.20039 10.0125 2.51013 10.2112 2.69987 10.3166C2.81654 10.3916 2.94154 10.4583 3.06654 10.525C4.49154 11.3083 6.0582 11.8333 7.64987 12.0916C7.72487 12.875 8.06653 13.7916 9.89153 13.7916C11.7165 13.7916 12.0749 12.8833 12.1332 12.075C13.8332 11.8 15.4749 11.2083 16.9582 10.3416C17.0082 10.3166 17.0415 10.2916 17.0832 10.2666C17.4034 10.0857 17.7352 9.86397 18.0398 9.6453C18.153 9.56403 18.2253 9.43789 18.2407 9.29938L18.2499 9.21664L18.2915 8.82498C18.2999 8.77498 18.2999 8.73331 18.3082 8.67497C18.3749 7.83331 18.3582 6.68331 17.5749 5.81664ZM10.9082 11.525C10.9082 12.4083 10.9082 12.5416 9.8832 12.5416C8.8582 12.5416 8.8582 12.3833 8.8582 11.5333V10.4833H10.9082V11.525ZM7.42487 4.64164V4.60831C7.42487 3.19164 7.42487 2.66664 9.36653 2.66664H10.6332C12.5749 2.66664 12.5749 3.19998 12.5749 4.60831V4.64998H7.42487V4.64164Z"
                        fill="#21222D"
                      />
                      <path
                        d="M17.2599 11.5091C17.614 11.3418 18.0212 11.6223 17.9858 12.0123L17.7002 15.1583C17.5252 16.825 16.8419 18.525 13.1752 18.525H6.82519C3.15853 18.525 2.4752 16.825 2.3002 15.1667L2.02948 12.1888C1.99444 11.8034 2.39276 11.5233 2.7458 11.682C3.705 12.1131 5.317 12.8064 6.35299 13.0854C6.51699 13.1296 6.65014 13.2476 6.72454 13.4003C7.23875 14.4556 8.32794 15.0167 9.89186 15.0167C11.4404 15.0167 12.5429 14.434 13.059 13.3755C13.1335 13.2227 13.2666 13.1048 13.4307 13.0604C14.5325 12.7619 16.2538 11.9846 17.2599 11.5091Z"
                        fill="#21222D"
                      />
                    </svg>
                    <div className="cardDetail">
                      <p className="heading">College Name</p>
                      <p className="content">
                        {menteeDetails?.mentorshipProfession?.company}
                      </p>
                    </div>
                  </div>
                  <div className="card">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.9998 11.6667C5.8248 11.6667 2.4248 14.4667 2.4248 17.9167C2.4248 18.15 2.60814 18.3334 2.84147 18.3334H17.1581C17.3915 18.3334 17.5748 18.15 17.5748 17.9167C17.5748 14.4667 14.1748 11.6667 9.9998 11.6667Z"
                        fill="#21222D"
                      />
                      <path
                        d="M13.0913 3.05002C12.3413 2.20002 11.2247 1.66669 9.99967 1.66669C8.83301 1.66669 7.76634 2.14169 7.00801 2.92502C6.28301 3.67502 5.83301 4.70835 5.83301 5.83335C5.83301 6.61669 6.04967 7.35002 6.44134 7.97502C6.64967 8.33335 6.91634 8.65835 7.23301 8.92502C7.95801 9.59169 8.92467 10 9.99967 10C11.5247 10 12.8413 9.18335 13.5663 7.97502C13.783 7.61669 13.9497 7.21669 14.0413 6.80002C14.1247 6.49169 14.1663 6.16669 14.1663 5.83335C14.1663 4.76669 13.758 3.79169 13.0913 3.05002ZM11.558 6.60002H8.44134C8.00801 6.60002 7.65801 6.25002 7.65801 5.81669C7.65801 5.38335 8.00801 5.03335 8.44134 5.03335H11.558C11.9913 5.03335 12.3413 5.38335 12.3413 5.81669C12.3413 6.25002 11.9913 6.60002 11.558 6.60002Z"
                        fill="#21222D"
                      />
                    </svg>
                    <div className="cardDetail">
                      <p className="heading">Degree</p>
                      <p className="content">
                        {menteeDetails?.mentorshipProfession?.designation}
                      </p>
                    </div>
                  </div>

                  <div className="card">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.0004 3.875C6.01706 3.875 2.77539 7.11667 2.77539 11.1C2.77539 15.0833 6.01706 18.3333 10.0004 18.3333C13.9837 18.3333 17.2254 15.0917 17.2254 11.1083C17.2254 7.125 13.9837 3.875 10.0004 3.875ZM10.6254 10.8333C10.6254 11.175 10.3421 11.4583 10.0004 11.4583C9.65872 11.4583 9.37539 11.175 9.37539 10.8333V6.66667C9.37539 6.325 9.65872 6.04167 10.0004 6.04167C10.3421 6.04167 10.6254 6.325 10.6254 6.66667V10.8333Z"
                        fill="#21222D"
                      />
                      <path
                        d="M12.4079 2.87502H7.59121C7.25788 2.87502 6.99121 2.60835 6.99121 2.27502C6.99121 1.94169 7.25788 1.66669 7.59121 1.66669H12.4079C12.7412 1.66669 13.0079 1.93335 13.0079 2.26669C13.0079 2.60002 12.7412 2.87502 12.4079 2.87502Z"
                        fill="#21222D"
                      />
                    </svg>
                    <div className="cardDetail">
                      <p className="heading">Current Year</p>
                      <p className="content">
                        {menteeDetails?.mentorshipProfession?.yrsOfExp} Years
                      </p>
                    </div>
                  </div>
                  {/* {console.log(details?.GetPrepProfessions[0]?.yrsOfExp)} */}
                </div>
              </div>
            </div>

            <div className="personalDetailOuter mt-40">
              <p className="heading">Keys Area of Intrest</p>
              <div className="personalDetailContainer">
                <div className="cardsOuter">
                  <div className="card fullCard">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.3332 6.50831V13.4916C18.3332 16.1666 16.1665 18.3333 13.4915 18.3333H6.50817C3.83317 18.3333 1.6665 16.1666 1.6665 13.4916V6.50831C1.6665 4.43331 2.5165 2.92498 4.02484 2.19165C4.57484 1.92498 5.20817 2.34165 5.20817 2.94998V10.35C5.20817 11.3416 5.5915 12.1333 6.28317 12.5333C6.98317 12.925 7.8665 12.8416 8.7665 12.3L9.84984 11.65C9.9165 11.6166 10.0832 11.6166 10.1332 11.6416L11.2332 12.3C11.8332 12.6583 12.3498 12.775 12.7665 12.775C13.1998 12.775 13.5332 12.6416 13.7332 12.525C14.4082 12.1333 14.7915 11.3416 14.7915 10.35V2.94998C14.7915 2.34165 15.4332 1.92498 15.9748 2.19165C17.4832 2.92498 18.3332 4.43331 18.3332 6.50831Z"
                        fill="#262626"
                      />
                      <path
                        d="M12.7085 1.66669C13.1668 1.66669 13.5418 2.04169 13.5418 2.50002V10.35C13.5418 10.8834 13.3835 11.2834 13.1085 11.4417C12.8252 11.6084 12.3752 11.525 11.8752 11.225L10.7752 10.5667C10.3502 10.3084 9.65016 10.3084 9.22516 10.5667L8.12516 11.225C7.62516 11.525 7.17516 11.6 6.89183 11.4417C6.61683 11.2834 6.4585 10.8834 6.4585 10.35V2.50002C6.4585 2.04169 6.8335 1.66669 7.29183 1.66669H12.7085Z"
                        fill="#262626"
                      />
                    </svg>

                    <div className="cardDetail">
                      <p className="heading">Domain name</p>
                      {/* <p className="content">FP&A | Insurance | Finance & Accounting</p> */}
                      {menteeDetails?.mentorshipInterests?.length > 0 ? (
                        menteeDetails?.mentorshipInterests?.map(
                          (value, index) =>
                            value?.interest && (
                              <p className="content" key={index}>
                                {value?.interest}
                              </p>
                            )
                        )
                      ) : (
                        <p></p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewProfile;
