import moment, { min } from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Button from "../../Button/Button";
import "./ConfirmationPopUp.css";
const ConfirmationPopUp = ({ setActive, setEndCall, startTime, meetLink }) => {
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [bit, setBit] = useState(0);
  var timer;

  useEffect(() => {
    if(!seconds) {
      let aj = new Date(startTime).getTime();
      let as = new Date().getTime();
      let sum = as - aj;
      console.log(new Date(startTime))
      console.log(new Date())
      setMinutes(+(sum / 60000).toFixed());
      setSeconds(1);
    }

    // const startMinutes = startTime.substring(0,2);
    // const startSeconds = startTime.substring(3,5);

    // const currentMinutes = new Date().getMinutes();
    // const currentSeconds = new Date().getSeconds();
    // console.log(currentSeconds)
  }, []);

  useEffect(() => {
    let timer;
    if (+seconds === 60) {
      setMinutes(prev => prev + 1);
      setSeconds(0);
    }

    if (bit === 0) {
      setBit(1);
      timer = setInterval(() => setSeconds(prev => prev + 1), 1000);
    }
    if(bit === 2) {
      clearInterval(timer);
    }
  }, [seconds]);

  useEffect(() => {
    if (+minutes === 30) {
      handleClick();
      setBit(2);
    };
  },[minutes])

  const handleClick = () => {
    setEndCall(true);
    setActive(false);
    setMinutes(0);
    setSeconds(0);
  };
  return (
    <div className="popup_container">
      <div className="PopupOuter">
        <div className="PopupHeading">
          <p className="PopupHead">
            Session time : &nbsp;
            <span className="PopupHead2">
              {minutes < 10 || minutes>= 0? + "0" + minutes : minutes}:
              {seconds < 10 ? "0" + seconds : seconds}
            </span>{" "}
          </p>
        </div>
        <div className="popup_body">
          <p>For Ending the ongoing session</p>
          <a href={meetLink} target="_blank">Meeting Link</a>
          <div className="popupBtns">
            <Button
              className={"confirmBtn2"}
              name={"End session"}
              onClick={handleClick}
            />
            {/* <Button className={'confirmBtn'} name={'Confirm'}  onClick={()=>handleClick()}/> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPopUp;
