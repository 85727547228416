import React from 'react'
import Button from '../../components/Button/Button';
import InputField from '../../components/InputField/InputField';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import './profileStyle.css';
import {menteeSliceData,saveMenteeDetails,getMenteeDetails,clearState} from "../../reducer/menteeSlice"
import * as filestack from "filestack-js";
import UploadImagePopup from '../../components/uploadImgPopup/UploadImagePopup'; 
import PageLoader from "../../components/PageLoader/PageLoader"
import { useState,useEffect } from 'react';
import ErrorToast from "../../components/Toast/ErrorToast"


const EditProfile = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const { menteeDetails, isFetching, isSuccess } =
    useSelector(menteeSliceData);
  
  let profileDetail = {
    linkedin: "",
    mobileNumber: "",
    interest: [],
    topics:[],
    college : "",
    degree : "",
    currentYear:"",
  };

  const intrestArr =[
    "Finance & Accounting",
    "Consulting",
    "Research",
    "Investment Banking",
    "Product Management",
    "Marketing",
    "Banking & Insurance",
    "E-Learning",
    "Technology",
    "UX/UI",
    "Sales",
    "Operations",
    "Human Resources",
    "Digital Transformation",
    "Entrepreneurship",
    "Customer Experience",
    "Data Science",
    "Cloud/Cyber-security",
    "IT Consulting",
    "PR",
    "Corporate",
    "Communication",
    "Startup",
    "Project Management",
    "Machine learning",
    "Supply chain",
    "FP&A"
  ]

  const topicArr = [
    "Career Advice",
    "Job Search",
    "Skill Development",
    "Higher Education",
    "Career Transition",
  ]
  const [profileData, setProfileData] = useState({ ...profileDetail });
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  const [selectedProfileKey, setSelectedProfileKey] = useState("");
  const [urlProfile, setUrlProfile] = useState(null);
  const [url, setUrl] = useState("");
  const [uploadPopUp, setUploadPopUp] = useState(false);
  const [load, setLoad] = useState(false);
  const [active, setActive] = useState(false);
  const client = filestack.init("AcpQ2RjKSnWT5GjS9f6wOz");
  const token = {};
  
 const profileDataOnChange = (e) => {
    const { name, value } = e.target;
   if(name!=="topics" && name!=="interest"){
     setProfileData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
     
  
  }
   else{
    if(profileData[name].includes(value)) {
      setProfileData(prev => ({
        ...prev,
        [name]: prev[name].filter((item) => item !== value)
      }));
    }
    else {
      if(profileData[name].length === 3) {
        return;
      }
      else {
        setProfileData((prevState) => ({
          ...prevState,
          [name]: [...prevState[name],value],
        }));
      }
    }
  };

   }

  console.log(profileData)

  const handleSubmit = () => {
    const obj = {
      linkedin: profileData?.linkedin,
      mobileNumber: profileData?.mobileNumber,
      interest: profileData?.interest,
      topics: profileData?.topics,
      companyName:profileData?.college,
      designation:profileData?.degree,
      experience:profileData?.currentYear,
      profileImg: urlProfile,
      
    };
    console.log("Object",obj);
    let status = true;
    for (const key in obj) {
      if (key === "linkedin" || key === "profileImg") continue;
      if (!obj[key]) {
        status = false;
        setIsShowError(true);
        setErrMessage("Please fill all fields!");
        return;
      }
    }
    if (status) dispatch(saveMenteeDetails(obj));
    else {
      setIsShowError(true);
      setErrMessage("Please fill all fields!");
    }
  };

  const handleUploadProfile = (event) => {
    console.log(event.target.files[0].type);
    if (
      event.target.files.length > 0 
    ) {
      const files = event.target.files[0];
      setLoad(true);
      client
        .upload(files, {}, token)
        .then((res) => {
          if (res) {
            setSelectedProfileKey(res.name);
            setUrl(res.url);
            setLoad(false);
            setActive(true);
            console.log("resulttt", active);
            // setUploadPopUp(false)
          } else {
            setLoad(false);
          }
        })
        .catch((err) => {
          setLoad(false);
          alert("image file not supported");
          // setIsShowError(true);
          // setErrMessage(`${err.status}!`);
          // setErrMessage("All fields are mandatory")
        });
    }
  };

  const clearProfile = () => {
    setUrl(null);
    setSelectedProfileKey(null);
  };

  const clearRemoveProfile = () => {
    setUploadPopUp(false);
    setUrlProfile(null);
  };

  useEffect(() => {
    dispatch(getMenteeDetails());
  }, []);

  useEffect(() => {
      setProfileData((prevState) => ({
        ...prevState,
        linkedin: menteeDetails?.linkedin,
        mobileNumber: menteeDetails?.mobileNumber,
        college: menteeDetails?.mentorshipProfession?.company,
        degree: menteeDetails?.mentorshipProfession?.designation,
        currentYear: menteeDetails?.mentorshipProfession?.yrsOfExp,
        interest: menteeDetails?.mentorshipInterests?.map(res=>res.interest),
        topics: menteeDetails?.mentorshipTopics?.map(res=>res.Topic)
      }));
      setUrlProfile(menteeDetails?.profileImg);
   
  }, [menteeDetails]);

  console.log(profileData,"pro");

  useEffect(() => {
    if (isSuccess) {
      dispatch(clearState());
      setProfileData({ ...profileDetail });
      localStorage.setItem("profileImg", urlProfile);
      Navigate("/profile", { exact: true });
    }
    // eslint-disable-next-line
  }, [isSuccess]);

  return (
    <>
    {(isFetching || load) && <PageLoader />}
    <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <div className="editWrapper">
      <UploadImagePopup
            active={active}
            popout={uploadPopUp}
            handleClose={() => {
              setUploadPopUp(false);
              setSelectedProfileKey("");
              setActive(false);
            }}
            handleOk={() => {
              setUploadPopUp(false);
              setSelectedProfileKey("");
              if (active) {

                setUrlProfile(url);
              }
              else{
                setIsShowError(true);
                // seterrMessage("Please upload image");
                setUploadPopUp(true);
              }
            }}
            handleUploadProfile={handleUploadProfile}
            value={selectedProfileKey}
            handleRemove={clearProfile}
            handleDelete={() => {
              if (selectedProfileKey !== "") {
                setActive(false);
              } else {
                setActive(true);
              }
            }}
            clearRemoveProfile={clearRemoveProfile}
            accept={"jpeg/jpg"}
          />
        <div className="headerSmall withButton">
          <p className="HighlightHeading"><span className="parent" onClick={()=>Navigate("/profile")}>My Profile </span>/ Edit Profile</p>
          <Button className={'imgButton GreenSlotButton rowStraight'} src={'/assets/icons/tick-circle.png'} name={'Save Changes'} onClick={()=>{handleSubmit()}}/>
        </div>

        <div className="editProfileWrapper mt-10">
          <div className="mentorProfile profileMentor">
            <div className="profileImageWrap">
              <img
                src={urlProfile
                  ? urlProfile
                  :"https://events.powerathletehq.com/wp-content/uploads/2018/06/grey-person-coming-soon-e1529779699986.jpg"} alt="" className="mentorImg"/>
              <img
                src="/assets/icons/editProfile.png"
                className="editIconProfile"
                onClick={() => setUploadPopUp(true)}
              />
            </div>

            <div className="mentorDetails">
              <p className="mentorName">{menteeDetails?.firstName ? menteeDetails?.firstName : ""} {menteeDetails?.lastName ? menteeDetails?.lastName : ""}</p>
                <p className="designation">{menteeDetails?.mentorshipProfession?.designation ? `${menteeDetails?.mentorshipProfession?.designation} at` : ""} {menteeDetails?.mentorshipProfession?.company ? menteeDetails?.mentorshipProfession?.company : ""}</p>
            </div>
          </div>

          <div className="profileDetails mt-40">           
            <InputField
              type={"text"}
              name="linkedin"
              value={profileData.linkedin}
              className={"FormInput mb-30"}
              placeholder={"linkedin.com/user"}
              id={"linkedinProfile"}
              labelName={"LinkedIn Profile"}
              onChange={profileDataOnChange}
            />

            <InputField
              type={"number"}
              name="mobileNumber"
              className={"FormInput"}
              placeholder={"9876543210"}
              value={profileData?.mobileNumber}
              id={"name"}
              labelName={"Contact Number"}
              onChange={profileDataOnChange}
            />
            <small className="smallNotification mt-10 mb-30">
              Contact number won’t be visible on your public profile
            </small>

            <div className="formGroup profileRadioGroup">
              <label htmlFor="radio-group" className="formLabel labelExtra">
                Key areas of professional interest [max 3] <span className="impRed">*</span> 
              </label>
              <div className="feedbackRadioGroup">
                  {intrestArr?.map((res, i)=>(
                   <div className="radioBtnOuter">
                    <input
                    value={res}
                      type="checkbox"
                      id={"i" + i}
                      name="interest"
                      checked={profileData?.interest?.length>0 && profileData?.interest.includes(res)}
                      onChange={profileDataOnChange}
                    />
                    <label for={"i" + i}>{res}</label>
                  </div>))}
                  {/* <div className="radioBtnOuter">
                    <input
                      type="checkbox"
                      id={`test2`}
                      name="radio-group"
                    />
                    <label htmlFor={`test2`}>FP&A</label>
                  </div>
                  <div className="radioBtnOuter">
                    <input
                      type="checkbox"
                      id={`test3`}
                      name="radio-group"
                    />
                    <label htmlFor={`test3`}>Actuary</label>
                  </div>
                  <div className="radioBtnOuter">
                    <input
                      type="checkbox"
                      id={`test4`}
                      name="radio-group"
                    />
                    <label htmlFor={`test4`}>Others</label>
                  </div>
                  <div className="radioBtnOuter">
                    <input
                      type="checkbox"
                      id={`test5`}
                      name="radio-group"
                    />
                    <label htmlFor={`test5`}>Insurance</label>
                  </div>
                  <div className="radioBtnOuter">
                    <input
                      type="checkbox"
                      id={`test6`}
                      name="radio-group"
                    />
                    <label htmlFor={`test6`}>Investment Banking</label>
                  </div> */}
              </div>
            </div>

            <div className="formGroup profileRadioGroup">
              <label htmlFor="radio-group" className="formLabel labelExtra">
                Mentorship Agenda [max 3]<span className="impRed">*</span>
              </label>
              <div className="feedbackRadioGroup">
                  {topicArr.map((res,i)=>(
                   <div className="radioBtnOuter">
                    <input
                     value={res}
                      type="checkbox"
                      id={"m" + i}
                      name="topics"
                      checked={profileData?.topics?.length>0 && profileData?.topics.includes(res)}
                      onChange={profileDataOnChange}
                    />
                    <label for={"m" + i}>{res}</label>
                  </div>))}
                  {/* <div className="radioBtnOuter">
                    <input
                      type="checkbox"
                      id={"id2"}
                      name="topics"
                    />
                    <label htmlFor="id2">Skill Development</label>
                  </div>
                  <div className="radioBtnOuter">
                    <input
                      type="checkbox"
                      id={"id3"}
                      name="topics"
                    />
                    <label htmlFor="id3">Higher Education</label>
                  </div>
                  <div className="radioBtnOuter">
                    <input
                      type="checkbox"
                      id={"id4"}
                      name="topics"
                    />
                    <label htmlFor="id4">Career Transition</label>
                  </div> */}
              </div>
            </div>
              <div className="editProfileDetailNew">
                <InputField
                  type={"text"}
                  className={"FormInput mb-30"}
                  value={profileData?.college}
                  name="college"
                  placeholder={"Enter College Name"}
                  id={"collegeName"}
                  labelName={"College"}
                  onChange={profileDataOnChange}
                />

                <InputField
                  type={"text"}
                  className={"FormInput mb-30"}
                  value={profileData?.degree}
                  name="degree"
                  placeholder={"Enter Degree"}
                  id={"Degree"}
                  labelName={"Degree Pursuing"}
                  onChange={profileDataOnChange}
                />

                <InputField
                  type={"text"}
                  className={"FormInput"}
                  value={profileData?.currentYear}
                  name='currentYear'
                  placeholder={"Current year"}
                  id={"currentYear"}
                  labelName={"Which Year"}
                  onChange={profileDataOnChange}
                />
              </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditProfile