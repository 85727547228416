import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from "../environments/environment";

let token;
let menteeId;
let courseId;


let setTokenValues = () => {
  token = localStorage.getItem("token");
  menteeId = localStorage.getItem("menteeId");
  courseId= localStorage.getItem("courseId");
};

const menteeRoute = "/api/mentorship/mentee/";
const mentorRoute = "/api/mentorship/mentor/";

export const getMenteeDetails = createAsyncThunk(
  "mentee/getMenteeDetails",
  async (thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL + menteeRoute + menteeId + "/getMenteeDetails",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const saveMenteeDetails = createAsyncThunk(
  "mentee/saveMenteeDetails",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL + menteeRoute + menteeId + "/saveMenteeDetails",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getAllMentors = createAsyncThunk(
  "mentee/getAllMentors",
  async (thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
       ( environment.baseURL + menteeRoute + "getAllMentors" +`${courseId !=="null" ? `?courseId=${courseId}` : ""}`),
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      } 
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getMentorInterviewSlots = createAsyncThunk(
  "mentee/getMentorInterviewSlots",
  async ({ date, mentorId }, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          menteeRoute +
          mentorId +
          "/getMentorInterviewSlots?date=" +
          date,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const bookSlot = createAsyncThunk(
  "mentee/bookSlot",
  async (slotId, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          menteeRoute +
          menteeId +
          "/slot/" +
          slotId +
          "/bookSlot",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getInteviewDetails = createAsyncThunk(
  "mentee/getInteviewDetails",
  async (slotId, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          menteeRoute +
          "slot/" +
          slotId +
          "/getInteviewDetails",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getUpcomingCalls = createAsyncThunk(
  "mentee/getUpcomingCalls",
  async (thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL + menteeRoute + menteeId + "/getUpcomingCalls",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getPastCalls = createAsyncThunk(
  "mentee/getPastCalls",
  async (thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL + menteeRoute + menteeId + "/getPastCalls",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getMentorDetails = createAsyncThunk(
  "mentee/getMentorDetails",
  async (mentorId, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL + menteeRoute + mentorId + "/getMentorDetails",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const loginUser = createAsyncThunk(
  "mentee/loginUser",
  async ({ email, password }, thunkAPI) => {
    try {
      const payload = {
        email: email,
        password: password,
      };

      console.log("nvnfjkvnfknvjfnfv fv f", payload);
      const response = await fetch(
        `${environment.baseURL}${menteeRoute}loginMentee`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        localStorage.setItem("token", data.Token);
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem(
          "name",
          data.Data.firstName + " " + data.Data.lastName
        );
        localStorage.setItem("menteeId", data.Data.id);
        localStorage.setItem("profileImg", data.Data.profileImg);
        localStorage.setItem("courseId",data.Data.courseId)

        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const changeStatus = createAsyncThunk(
  "mentor/changeStatus",
  async ({ bookingId, status, ongoingTime}, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          menteeRoute +
          "booking/" +
          bookingId +
          "/changeStatus",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({ status,ongoingTime }),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const changePassword = createAsyncThunk(
  "mentor/changePassword",
  async ({newPassword, oldPassword, menteeId}, thunkAPI) => {
    try {
      setTokenValues();
      const obj = {
        oldPassword: oldPassword,
        password: newPassword
      }
      const response = await fetch(
        environment.baseURL +
          menteeRoute +
          "changePassword/" +
          menteeId, 
      
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e);
    }
  }
);

export const getMentorAllSlots = createAsyncThunk(
  "mentee/getMentorAllSlots",
  async (mentorId, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
        mentorRoute +
          mentorId +
          "/getMentorAllSlots",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
); 

export const menteeSlice = createSlice({
  name: "mentee",
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    allMentors: [],
    mentorAvailableSlots: [],
    interviewConfirmed: [],
    upcomingCalls: [],
    pastCalls: [],
    mentorDetails: [],
    menteeDetails: [],
    mentorAllSlotsData:{},
    token: localStorage.getItem("token") ? localStorage.getItem("token") : null,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    },
  },
  extraReducers: {
    [getAllMentors.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.allMentors = payload;
      return state;
    },
    [getAllMentors.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getAllMentors.pending]: (state) => {
      state.isFetching = true;
    },
    [getMentorInterviewSlots.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.mentorAvailableSlots = payload;
      return state;
    },
    [getMentorInterviewSlots.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getMentorInterviewSlots.pending]: (state) => {
      state.isFetching = true;
    },
    [bookSlot.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [bookSlot.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [bookSlot.pending]: (state) => {
      state.isFetching = true;
    },
    [getInteviewDetails.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.interviewConfirmed = payload;
      return state;
    },
    [getInteviewDetails.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getInteviewDetails.pending]: (state) => {
      state.isFetching = true;
    },
    [getUpcomingCalls.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.upcomingCalls = payload;
      return state;
    },
    [getUpcomingCalls.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getUpcomingCalls.pending]: (state) => {
      state.isFetching = true;
    },
    [getPastCalls.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.pastCalls = payload;
      return state;
    },
    [getPastCalls.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getPastCalls.pending]: (state) => {
      state.isFetching = true;
    },
    [getMentorDetails.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.mentorDetails = payload;
      return state;
    },
    [getMentorDetails.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getMentorDetails.pending]: (state) => {
      state.isFetching = true;
    },
    [getMenteeDetails.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.menteeDetails = payload;
      return state;
    },
    [getMenteeDetails.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getMenteeDetails.pending]: (state) => {
      state.isFetching = true;
    },
    [saveMenteeDetails.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [saveMenteeDetails.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [saveMenteeDetails.pending]: (state) => {
      state.isFetching = true;
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      state.token = payload?.Token;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [loginUser.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
    [changeStatus.pending]: (state) => {
      state.isFetching = true;
    },
    [changeStatus.fulfilled]: (state) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [changeStatus.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [changePassword.fulfilled]: (state) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [changePassword.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [changePassword.pending]: (state) => {
      state.isFetching = true;
    },
    [getMentorAllSlots.pending]: (state) => {
      state.isFetching = true;
    },
    [getMentorAllSlots.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.mentorAllSlotsData = payload;
      return state;
    },
    [getMentorAllSlots.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
     }
  },
});

export const { clearState } = menteeSlice.actions;

export const menteeSliceData = (state) => state.mentee;
